import Movie from "./Movie";
import { initializeApp } from "firebase/app";
import { getFirestore, orderBy } from "firebase/firestore";
import {
  collection,
  getDocs,
  query,
  limit,
  startAt,
  endAt,
} from "firebase/firestore";
import { ClipLoader } from "react-spinners";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Movies() {

  const firebaseConfig = {
    apiKey: "AIzaSyAitB4yZLmRCd4NK_y54xNLfs92E3KI22g",
    authDomain: "movies-fc2b1.firebaseapp.com",
    projectId: "movies-fc2b1",
    storageBucket: "movies-fc2b1.appspot.com",
    messagingSenderId: "1028587674738",
    appId: "1:1028587674738:web:ce1c46eac2ced43fb176eb",
    measurementId: "G-ZQNNHLY8DE",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  //obtener movies from firebase
  const [movies, setMovies] = useState([]);
  const [moviesFiltrado, setMoviesFiltrado] = useState([]);
  const [generos, setGeneros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [genero, setGenero] = useState(0);

  const [ordenamiento, setOrdenamiento] = useState('fecha_creacion');
  const [page, setPage] = useState(1);
  const [ano, setAno] = useState(0);
  const [nombre, setNombre] = useState("");
  const [timer, setTimer] = useState(null)


  const obtenerMovies = async () => {
    setLoading(true);
    const db = getFirestore(app);

    //obtener movies from firebase
    //const moviesCol = collection(db, "movies").orderBy("fecha_lanzamiento");
    const moviesCol= query(collection(db, "movies"), orderBy(ordenamiento, "desc"), limit(200));
    const moviesSnapshot = await getDocs(moviesCol);
    let moviesList = moviesSnapshot.docs.map((doc) => doc.data());
    for (let i = 0; i < moviesList.length; i++) {
      let descargada=false;
      for (let j = 0; j < moviesList[i].links.length; j++) {
        for (let k = 0; k < moviesList[i].links[j].enlaces.length; k++) {
          if(moviesList[i].links[j].enlaces[k].estado==1 || moviesList[i].links[j].enlaces[k].estado==2){
            descargada=true;
            break;
          }
        }
      }
      
      moviesList[i].descargada=descargada;
    }

    setMovies(moviesList);
    //console.table(moviesList);
    setMoviesFiltrado(moviesList);
    //setMovies(res.data);
    setLoading(false);
    
  };

  const obtenerGenerosTMDB = async () => {
    const url = `https://api.themoviedb.org/3/genre/movie/list?api_key=27e3e0e4de6cec566e26c2c089566fe7&language=es-MX`;
    const resp = await fetch(url);
    const data = await resp.json();
    setGeneros(data.genres);
  };


  const onChangeAno = (e) => {
    setAno(e.target.value);
  };

  const onchangeGenero = (e) => {
    setGenero(e.target.value);
  };


  const onchangeNombre = async (e) => {
    
    
    if(e.target.value!=""){
      //console.log(movies);
      //hacer consutla post a function https://us-central1-movies-fc2b1.cloudfunctions.net/busqueda
      
     
      clearTimeout(timer)

      const newTimer = setTimeout(async () => {
        let data = await axios.post('https://us-central1-movies-fc2b1.cloudfunctions.net/busqueda', {
          busqueda: e.target.value,
          tipo: "movies_dev"
        })
      
        setMoviesFiltrado(data.data);
      }, 800)

      setTimer(newTimer)
    }else{
      setMoviesFiltrado(movies);
    }

  };



  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } 

  const onchangeOrdenamiento = (e) => {
    setOrdenamiento(e.target.value);
    //guardar en localstorage
    localStorage.setItem('ordenamiento', e.target.value);
  };

  useEffect(() => {
    if (generos.length === 0) {
      obtenerGenerosTMDB();
    }
    obtenerMovies();
  }, [nombre, ano, genero, ordenamiento, generos.length]);

  useEffect(() => {
    //obtener de localstorage
    let ordenamiento = localStorage.getItem('ordenamiento');
    if(ordenamiento){
      console.log(ordenamiento);
      setOrdenamiento(ordenamiento);
    }
  }, []);


  return (
    <>
      <div className="container">
        <div className="columns is-mobile is-multiline">
          
          <div className="column is-one-quarter-desktop  is-half-mobile is-half-tablet">
            <div className="field">
              <label className="label">Buscador</label>
              <div className="control">
                <input
                  className="input is-info"
                  type="text"
                  placeholder="Buscar película"
                  onChange={onchangeNombre}
                />
              </div>
            </div>
          </div>

          <div className="column is-one-quarter-desktop  is-half-mobile is-half-tablet">
          <div className="field">
              <label className="label">Ordernar por </label>
              <div className="control">
                <div className="select is-info">
                  <select onChange={onchangeOrdenamiento} value={ordenamiento}>
                    <option value="fecha_creacion">Fecha de creación</option>
                    <option value="fecha_lanzamiento">Fecha de lanzamiento</option>
                    <option value="fecha_modificacion">Fecha de modificación</option>
                    <option value="metadatos_the_movie_db.popularity">Popularidad</option>
                    <option value="metadatos_the_movie_db.vote_average">Calificación</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

        </div>

        
      <div className="columns is-multiline is-mobile">
        {loading ? (
          //centrar el loader
          <div
            className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ClipLoader size={50} color={"#3e8ed0"} />
          </div>
        ) : (
          //solo mostrar las primeras 10 peliculas
          moviesFiltrado.map((movie,index) => (
            <div key={index} className="column is-one-quarter-desktop  is-half-mobile is-half-tablet">
             
              <Movie key={movie.uid} {...movie} />
             
            </div>
          ))
        )}
      </div>
      </div>
    </>
  );
}
