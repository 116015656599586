import {Link  } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import poster from "../../images/poster.jpg";
import ReactStars from "react-stars";
function Movie(data) {
  return (
    <>
      <Link  to = {`/movies/${data.uid}`} className="card" preventScrollReset={true}>
        <div className="card">         
          <div className="card-image">
            {data.Poster_path!==null && data.Poster_path!=="null" ? (
              <figure className="image is-2by3">
                <LazyLoad width={500} offset={500} >
                  <img alt="" src={"https://image.tmdb.org/t/p/w500"+data.portada}/>
                </LazyLoad>
              </figure>
            ) : (
              <figure className="image is-2by3">
                <LazyLoad width={500} offset={500} >
                  <img alt="" src={poster}/>
                </LazyLoad>
              </figure>
            )}
          
           
          </div> 
          <div className="card-footer">
            <div className="card-footer-item">
              <p className="title is-6" style={{ fontSize:'12px' }}> {data.titulo_sin_info.length > 30 ? data.titulo_sin_info.substring(0, 30) + "..." : data.titulo_sin_info}</p>
            </div>
            {data.descargada ? (
            <div className="card-footer-item">
              <span className="tag is-success">Descargada</span>
            </div>):(
              <div className="card-footer-item">
               <ReactStars
                            count={5}
                            size={14}
                            color2={"#ffd700"}
                            value={data.metadatos_the_movie_db.vote_average / 2}
                            edit={false}
                          />
              </div>
            )}
          </div>
        </div>
      </Link >
    </> 
    );
  }
  
  export default Movie;