import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyAitB4yZLmRCd4NK_y54xNLfs92E3KI22g",
    authDomain: "movies-fc2b1.firebaseapp.com",
    projectId: "movies-fc2b1",
    storageBucket: "movies-fc2b1.appspot.com",
    messagingSenderId: "1028587674738",
    appId: "1:1028587674738:web:ce1c46eac2ced43fb176eb",
    measurementId: "G-ZQNNHLY8DE",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        photoURL: user.photoURL,
        activo: false,
        fecha_registro: new Date(),
        rango: 2
      });
    }else{
      if(docs.docs[0].data().activo === false){
        //enviar a la pagina de pago
        
      }
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};


export {
  auth,
  db,
  signInWithGoogle,
  logout,
};