import './App.css';
import Navbar from './components/navbar/Navbar';
import Movies from './components/movies/Movies';
import { Route, Routes, BrowserRouter,Router, Navigate } from 'react-router-dom';
import MovieDetalle from './components/movies/MovieDetalle';
import Series from './components/series/Series';
import SerieDetalle from './components/series/SerieDetalle';
import Account from './components/account/index';
import Pay from './components/account/Pay';
import Login from './login';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth} from "./firebase";
import Busqueda from './components/busqueda/Busqueda';

function App() {

  const [user, loading, error] = useAuthState(auth);

  return (
    <>
     <BrowserRouter>
      <Navbar />

      {user ? (
        <div className='container' style={{padding:'20px'}}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path='/account' element={<Account />} />
            <Route path='/pay' element={<Pay />} />
            <Route path='/movies' element={<Movies />} />
            <Route path='/series' element={<Series />} />
            <Route path='/busqueda' element={<Busqueda />} />

            <Route path='/series/:id' element={<SerieDetalle />} />
            <Route path='/movies/:id' element= {< MovieDetalle />} />

            <Route path='/login' element={<Login/>} />
            <Route path='*' element={<h1>404 Not Found</h1>} />
          </Routes>
        </div>) : (
          <Routes>
           <Route exact path="/" element={<Login />} />
           <Route path='/login' element={<Login/>} />
           <Route path='*' element={<h1>404 Not Found</h1>} />
         </Routes>
          
        )}
        
    </BrowserRouter>
   </>
  );
}

export default App;
