import React, {useState,useEffect} from "react";
import { getFirestore } from "firebase/firestore";
import { collection,addDoc } from "firebase/firestore";
export default function Busqueda(){

    const [movies, setMovies] = useState([]);
    const [busqueda, setBusqueda] = useState("");
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [movieEscaneando, setMovieEscaneando] = useState(null); //array de objetos [
    const [timer, setTimer] = useState(null)

    useEffect(() => {
        obtenerMovies();
        }, [busqueda]);


    const obtenerMovies = async () => {
        clearTimeout(timer)
        const newTimer = setTimeout(async () => {
            const url = `https://g3fjt738s5.execute-api.us-west-2.amazonaws.com/prod/search_movie?movie_name=${busqueda}`;
            const resp = await fetch(url);
            const data = await resp.json();
            setMovies(data);
        }, 800)
        setTimer(newTimer);
        };


    const agregarMoviesWeb = async (movie) => {
        setMovieEscaneando(movie);
        //agregar en firebase enlace para escanear en la coleccion movies_web
        //console.log(movie);
        //hacer una peticion a https://g3fjt738s5.execute-api.us-west-2.amazonaws.com/prod/movie_details?url=
        //para obtener los detalles de la pelicula
        setIsLoadingButton(true);
        const url = `https://g3fjt738s5.execute-api.us-west-2.amazonaws.com/prod/movie_details?url=${movie.link}`;
        const resp = await fetch(url);
        const data = await resp.json();
        const db = getFirestore();
        const docRef = await addDoc(collection(db, "movies_web"), {
           link: data.paste_link,
           escaneado: false,
        });
        
        setIsLoadingButton(false);
        setMovieEscaneando(null);
        //agregar enlace en la coleccion links
    }




    return(



        <div>
            <h1>Busqueda</h1>
            <div className="field has-addons">
            <input type="text" placeholder="Buscar" className="input is-info" onChange={(e) => setBusqueda(e.target.value)} />
            <button className="button is-info">Buscar</button>
            </div>
            <div className="columns is-mobile is-multiline">
                {movies.map((movie,index) => (
                    <div className="column is-one-quarter-desktop  is-half-mobile is-half-tablet">
                        <div className="card">
                            <div className="card-image">
                                <figure className="image is-2by3">
                                    <img src={movie.image_url} alt={movie.title} />
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content has-text-centered">
                                        <p className="subtitle is-6">{movie.title}</p>
                                        <p className="subtitle is-6">
                                            {isLoadingButton && movie==movieEscaneando ? (
                                                    <button id={index} className="button is-info is-loading">Cargando</button>
                                                ) : (
                                                    <button id={index} className="button is-info" onClick={() => agregarMoviesWeb(movie)}>Agregar</button>
                                                )
                                            }                                            
                                        </p>
                                        {/* <p className="subtitle is-6">{movie.year}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div> 


        </div>
    )
}