import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithGoogle,logout } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { FcGoogle } from 'react-icons/fc';



function Login() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      navigate("/movies");
    }
  }, [user, loading]);
  return (
    //centered login form bulma


    <div className="container content has-text-centered">
      <div className="columns is-centered">
        <div className="column is-half">
            <div className="field">
              <label className="label is-size-4 is-primary">Iniciar sesión</label>
            </div>
            <div className="field">
              <div className="control">  
                <button className="button" onClick={signInWithGoogle}>
                  <span className="icon">
                    <FcGoogle />
                  </span>
                  <span>Continuar con Google</span>
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
export default Login;