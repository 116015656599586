import {  useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, query, where,updateDoc,addDoc } from "firebase/firestore";
import { ClipLoader } from "react-spinners";
import { useEffect, useState } from "react";

import ReactStars from "react-stars";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload,faCheck,faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

function SerieDetalle() {


  const firebaseConfig = {
    apiKey: "AIzaSyAitB4yZLmRCd4NK_y54xNLfs92E3KI22g",
    authDomain: "movies-fc2b1.firebaseapp.com",
    projectId: "movies-fc2b1",
    storageBucket: "movies-fc2b1.appspot.com",
    messagingSenderId: "1028587674738",
    appId: "1:1028587674738:web:ce1c46eac2ced43fb176eb",
    measurementId: "G-ZQNNHLY8DE",
  };
  const navigate = useNavigate();

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const { id } = useParams();
  const [movie, setMovie] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingLista, setLoadingLista] = useState(true);
  const [generos, setGeneros] = useState([]);
  const [estadosPeliculas, setEstadosPeliculas] = useState([]);
  const [reescaneando, setReescaneando] = useState(false);
  //ejecutar un useEffect para obtener los datos de la pelicula cada 10 segundos
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     obtenerMovies();
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []); 

  




  const obtenerMovies = async () => {
    const db = getFirestore(app);
    const q = query(collection(db, "series"), where("uid", "==",id ));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("no hay resultados");
      //enviar a 404
      navigate("/404");

    }

    const docs = querySnapshot.docs.map((doc) => doc.data());

    //console.table(docs[0]);
    
    setMovie(docs[0]);
    setLoading(false);
    
    setLoadingLista(false);

  };

  const obtenerGenerosTMDB = async () => {
    const url = `https://api.themoviedb.org/3/genre/tv/list?api_key=27e3e0e4de6cec566e26c2c089566fe7&language=es-MX`;
    const resp = await fetch(url);
    const data = await resp.json();
    setGeneros(data.genres);
    obtenerMovies();
  };

  //disable class by id 
  const changeClass = (uuid) => {
    document.getElementById(uuid).classList.toggle("is-loading");
    
  };

  const descargaPelicula = async (id, uuid,full=false) => {
    //update movie status
    const db = getFirestore(app);
    const q = query(collection(db, "series"), where("uid", "==",id ));
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map((doc) => doc.data());
    let links = docs[0].links;
    let url = "";

    //recorrer links
    links.forEach((link) => {
      link.enlaces.forEach((enlace) => {
        if (enlace.uuid == uuid) {
          enlace.estado = 1;
          url = enlace.url;
        }
      });
    });

    console.table(links);
    //update docs
    const docRef = collection(db, "series");

    const doc = await getDocs(docRef);


    for await (const doccc of doc.docs) {
      if (doccc.data().uid == id) {
        await updateDoc(doccc.ref, {
          links: links,
        });
      }
    }

    // doc.forEach(async (doc) => {
    //   if (doc.data().uid == id) {
    //     await updateDoc(doc.ref, {
    //       links: links,
    //     });
    //   }
    // });



    //insertar en la lista de descargas
    let url_limpia= url.replace(/_/g, "");
    url_limpia= url_limpia.replace(/#/g, "");
    const docRef2 = collection(db, "descargas");
    const doc2 = await addDoc(docRef2, {
      uuid: uuid,
      id: id,
      url: url,
      url_limpia: url_limpia,
      estado: 0,
      fecha: new Date(),
      renombrado: 0,
    });

    if(!full){
      obtenerMovies();	
    }
    
    
   

  };

  const rescanear= async () => {
    setReescaneando(true);

    movie.links.forEach(async (link) => {
       //agregar a una tabla de reescaneo
      const db = getFirestore(app);
      const docRef2 = collection(db, "reescaneo");
      const doc2 = await addDoc(docRef2, {
        url: link.origen,
        fecha: new Date(),
        realizado: false
      });

    });

    //esperar 10 segundos
    setTimeout(() => {
      //obtenerMovies();
      setReescaneando(false);
    }, 8000);


   
    
  };

  const descargarTodo = async () => {
   //recorrer todos los links y descargarlos con un for await
   for await (const link of movie.links) {
    for await (const enlace of link.enlaces) {
      if(enlace.estado == 0){
        await descargaPelicula(movie.uid, enlace.uuid,true);
      }
    }
  }




    obtenerMovies();
  };

  useEffect(() => {
    obtenerGenerosTMDB();
  
  }, []);

  return (
    <>
      {loading ? (
        //centrar el loader
        <div
            className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ClipLoader size={50} color={"#3e8ed0"} />
          </div>
        
      ) : (
        <>
          <div className="columns is-mobile">
            <div className="column ">
              <div className="card" >
                <div className="columns ">
                  <div className="column is-2">
                    <div className="card-image">
                      <figure className="image is-2by3">
                        <img
                          alt=""
                          src={
                            "https://image.tmdb.org/t/p/w500" +
                            movie.portada
                          }
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="column is-10">
                    <div className="card-content">
                      <div className="media">
                        <div className="media-content">
                          <p className="title is-4">{movie.titulo_sin_info}</p>
                          <p className="subtitle is-6">
                            {movie.metadatos_the_movie_db.original_title}
                          </p>
                          <ReactStars
                            count={5}
                            size={24}
                            color2={"#ffd700"}
                            value={movie.metadatos_the_movie_db.vote_average / 2}
                            edit={false}
                          />
                          <p className="subtitle is-6">
                            {movie.metadatos_the_movie_db.release_date}
                          </p>
                          <p className="subtitle is-6">
                            {movie.metadatos_the_movie_db.overview}
                          </p>

                            <div className="tags">
                              {movie.metadatos_the_movie_db.genre_ids.map((genero) => {
                                return (
                                  <span className="tag is-info" key={genero}>
                                    {generos.find((g) => g.id === genero).name}{" "}
                                  </span>
                                );
                              })}
                            </div>

                            <div >
                              <button className={reescaneando ? "button is-primary is-loading" : "button is-primary"} onClick={rescanear}>Reescanear</button>
                            </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <div className="table-container">

                    {!loadingLista ? (
                    <table className="table is-striped is-fullwidth">
                      <thead>
                        <tr>
                          <th>Calidad</th>
                          <th>Origen</th>
                          <th>Tipo de rip</th>
                          <th>Fecha de registro</th>
                          <th>Enlaces <button className="button is-primary is-small" onClick={descargarTodo}>Descargar todo</button></th>
                    
                        </tr>
                      </thead>
                      <tbody>
                        {movie.links.map((link, index) => {
                          return (
                            <tr key={index}>
                              <td>{link.calidad}</td>
                              <td>{link.origen}</td>
                              <td>{link.rip}</td>
                              <td>
                                {link.fecha_registro
                                  ? link.fecha_registro
                                      .toDate()
                                      .toLocaleString()
                                  : ""}
                              </td>
                              <td>
                                {link.enlaces.map((enlacess, index) => {
                                  return (
                                    <>
                                    <div key={index} style={{display:"flex", justifyContent:"space-between"}}>
                                      
                                      
                                        
                                       <p>{enlacess.url.split("//")[1]}</p>
                                      
                                    {enlacess.estado !=1 ? (
                                      <button
                                        className={enlacess.estado == 0 ? "button is-primary is-small" : "button is-success is-small"}
                                        
                                        onClick={() => {
                                          
                                          descargaPelicula(movie.uid, enlacess.uuid);
                                        }

                                        
                                        
                                        }

                                        disabled = {enlacess.estado > 0 ? true : false}

                                        >
                                          {enlacess.estado == 0 ? (
                                        <FontAwesomeIcon icon={faDownload} />
                                      ) : enlacess.estado == 1 ? (
                                        <FontAwesomeIcon icon={faSpinner} />
                                      ) :
                                      
                                      (
                                        <FontAwesomeIcon icon={faCheck} />
                                      )}
                                        </button>
                                    ) : (
                                      <></>
                                    )}

                                    </div>
                                    {enlacess.estado == 1 ? (
                                      <>
                                    <progress className="progress is-success" value={enlacess.porcentaje} max="100">{enlacess.porcentaje}%</progress>
                                    {enlacess.porcentaje}% de descarga
                                    </>
                                    ) : (
                                      <></>
                                    )}
                                    </>


                                  );
                                })}
                              </td>
                             
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Las versiones BRRip = 4gb<br/>
                              Las versiones BDRip = 8gb
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                    ) : (
                      <div
                        className="container"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ClipLoader size={50} color={"#3e8ed0"} />
                      
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ToastContainer limit={1} />
    </>
  );
}

export default SerieDetalle;
