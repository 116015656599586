import {Link  } from "react-router-dom";
import LazyLoad from "react-lazy-load";


function Serie(data) {
  return (
    <>
      <Link  to = {`/series/${data.uid}`} className="card">
        <div className="card">
          <div className="card-image">
            <figure className="image is-2by3">
              <LazyLoad width={500} offset={500} >
                <img alt="" src={"https://image.tmdb.org/t/p/w500"+data.portada}/>
              </LazyLoad>
            </figure>
          </div> 
        </div>
      </Link >
    </> 
    );
  }
  
  export default Serie;