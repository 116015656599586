import { initializeApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth} from "../../firebase";
import { useState } from 'react';
import { updateDoc } from "firebase/firestore";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import mercadologo from '../../images/mercado.svg';


const firebaseConfig = {
  apiKey: "AIzaSyAitB4yZLmRCd4NK_y54xNLfs92E3KI22g",
  authDomain: "movies-fc2b1.firebaseapp.com",
  projectId: "movies-fc2b1",
  storageBucket: "movies-fc2b1.appspot.com",
  messagingSenderId: "1028587674738",
  appId: "1:1028587674738:web:ce1c46eac2ced43fb176eb",
  measurementId: "G-ZQNNHLY8DE",
};
const app = initializeApp(firebaseConfig);
const public_key="APP_USR-e3f09bf9-d1ce-4b76-b108-4f8cc8df50a3";
const db = getFirestore(app);
function Pay(){


    const [user, loading, error] = useAuthState(auth);

    

    const planes = [
      {
          id: 1,
          nombre: 'Premium',
          precio: 129.00,
          pantallas: 4,
      },
      {
          id: 2,
          nombre: 'Estándar',
          precio: 99.00,
          pantallas: 2,
      },
      {
          id: 3,
          nombre: 'Básico',
          precio: 69.00,
          pantallas: 1,
      },
    ]


    const [plan, setPlan] = useState(null);
    const [procesando, setProcesando] = useState(false);

    const [imagen, setImagen] = useState(null);

    const [voucher, setVoucher] = useState(null);

    const [cardFormData, setCardFormData] = useState({
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        securityCode: '',
        cardholderName: '',
        installments: '1',
        cardholderEmail: user.email,
      });
  
    
      const onSubmit = async (event) => {
        setProcesando(true);
        event.preventDefault();
        try{
          let {
            cardNumber,
            expirationMonth,
            expirationYear,
            securityCode,
            cardholderName,
            installments,
            cardholderEmail,
          } = cardFormData;


          //validar todos los campos
          if(cardNumber=="" || expirationMonth=="" || securityCode=="" || cardholderName=="" || installments=="" || cardholderEmail=="" || plan==null || expirationYear==""){
            alert("Todos los campos son obligatorios");
            setProcesando(false);
            return;
          }

          //validar expiracion
          let fecha=new Date();
          let mes=fecha.getMonth()+1;
          let anio=fecha.getFullYear();
          if(Number(expirationYear)<anio || (Number(expirationYear)==anio && Number(expirationMonth)<mes)){
            alert("La tarjeta está vencida");
            setProcesando(false);
            return;
          }

          let response4= await fetch("https://api.mercadopago.com/v1/devices/widgets?"+new URLSearchParams({
            public_key: public_key,
            locale: "en",
            js_version: "2.0.0",
            referer: "file%3A%2F%2F",
          }), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              section: "open_plataform_V2",
              view: "checkout",
            }),
          });
          const data4 = await response4.json();
          const device=data4.session_id;

          cardNumber= cardNumber.replace(/\s/g, '');

          //console.log(cardFormData);
          let response2= await fetch("https://api.mercadopago.com/v1/payment_methods/search?"+new URLSearchParams({
            public_key: public_key,
            bins: cardNumber.substring(0,6),
            locale: "en",
            js_version: "2.0.0",
            status: "active",
            processing_mode: "aggregator",
            referer: "file%3A%2F%2F",
            marketplace: "NONE",

          }), {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
      

          });
          const data2 = await response2.json();
          const issuer=data2["results"][0]["issuer"]["id"];
          const payment_method_id=data2["results"][0]["id"];


        let response=await fetch("https://api.mercadopago.com/v1/card_tokens?"+new URLSearchParams({
          public_key: public_key,
          locale: "en",
          js_version: "2.0.0",
          referer: "file%3A%2F%2F",
        }), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              card_number: cardNumber,
              expiration_month: expirationMonth,
              expiration_year: expirationYear,
              securityCode: securityCode,
              cardholder: {
                name: cardholderName,
                identification: {
                },
              },
              device: {
                meli: {
                  session_id: device,
                },
              },

              issuer: issuer,
              installments: installments,
              cardholderEmail: cardholderEmail,
            }),
          });
          const data = await response.json();

          const token=data.id;
          const response3=await fetch("https://us-central1-movies-fc2b1.cloudfunctions.net/procesar", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: token,
              issuer_id: issuer.toString(),
              payment_method_id: payment_method_id,
              transaction_amount: Number(plan.precio),
              installments: Number(installments),
              description: "Plan " + plan.nombre,
              payer: {
                email: cardholderEmail,
                identification: {
              
                },
              },
            }),
          });


          const data3 = await response3.json();
          const idpago=data3.id;


          if(data3.status=="approved"){
            //reenviar a la pagina de inicio
            const q = query(collection(db, "users"), where("uid", "==", user.uid));
            const docs = await getDocs(q);
            if (docs.docs.length !== 0) {
            await updateDoc(docs.docs[0].ref, {
                activo: true,
              });
            }

            let fecha=new Date();
            //aumentar 1 mes
            let fecha_renovacion=fecha.setMonth(fecha.getMonth()+1);


            //crear pago en firebase
            await addDoc(collection(db, "pagos"), {
              uid: user.uid,
              plan: plan.nombre,
              precio: plan.precio,
              fecha: new Date(),
              fecha_renovacion: new Date(fecha_renovacion),
              id_pago: idpago,
              idplan: plan.id,
            });


            alert("Pago exitoso");
            window.location.href = "/account";

          }else{
            alert("Pago rechazado, intente de nuevo, verifique los datos de su tarjeta");
            setProcesando(false);
          }
        }catch(error){
          console.log(error);
          alert("Error al procesar el pago, si el problema persiste, intente de nuevo más tarde");
          setProcesando(false);
        }

      };
    
      const canjeoVoucher = async (event) => {
        
        try{
          setProcesando(true);
          const q = query(collection(db, "users"), where("uid", "==", user.uid));
          const docs = await getDocs(q);
          if (docs.docs.length !== 0) {
          await updateDoc(docs.docs[0].ref, {
              activo: true,
            });
          }

          let fecha=new Date();
          //aumentar 1 anios
          let fecha_renovacion=fecha.setFullYear(fecha.getFullYear()+1);

          //crear pago en firebase
          await addDoc(collection(db, "pagos"), {
            uid: user.uid,
            plan: planes[0].nombre,
            precio: planes[0].precio *12,
            fecha: new Date(),
            fecha_renovacion: new Date(fecha_renovacion),
            id_pago: 0,
            idplan: planes[0].id,
          });


          alert("Pago exitoso");
          window.location.href = "/account";
        }catch(error){
          console.log(error);
          setProcesando(false);
        }
      }
    
      const handleChange = (event) => {
        let { id, value } = event.target;
        //eliminar espacios en blanco
        if (id === 'cardNumber'){
          //permitir solo numeros
          value=value.replace(/\D/g, '');
          //eliminar espacios en blanco
          value=value.replace(/\s/g, '');
          //agregar un espacio cada 4 digitos
          value=value.replace(/(.{4})/g, '$1 ');
          //eliminar ultimo espacio
          value=value.trim();
          //limitar a 19 caracteres
          value=value.substring(0,19);

          obtenerImagenTarjeta(value);

        }
        if (id === 'securityCode'){
          //permitir solo numeros
          value=value.replace(/\D/g, '');
          //limitar a 4 caracteres
          value=value.substring(0,4);
        }

        if (id === 'cardholderName'){
          //limitar a 50 caracteres
          value=value.substring(0,50);
        }

        
         

        console.log(id, value);
        setCardFormData({ ...cardFormData, [id]: value });

      };


      const obtenerImagenTarjeta = async (value) => {
        let cardNumber=value;

          //Eliminar espacios en blanco
          cardNumber= cardNumber.replace(/\s/g, '');

          console.log(cardNumber);
          if (cardNumber.length<6){
            setImagen(null);
            return;
          }
        
        let response2= await fetch("https://api.mercadopago.com/v1/payment_methods/search?"+new URLSearchParams({
            public_key: public_key,
            bins: cardNumber.substring(0,6),
            locale: "en",
            js_version: "2.0.0",
            status: "active",
            processing_mode: "aggregator",
            referer: "file%3A%2F%2F",
            marketplace: "NONE",

          }), {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
      

          });
          const data2 = await response2.json();
          const imagen=data2["results"][0]["secure_thumbnail"];
          setImagen(imagen);
      }

    
    return(
        <div>
            <h2 className="title">Pago</h2>
            
            {/* dibujar cards de planes */}
            <div className="columns">
            { plan == null ? 
               planes.map((plan) => (
                <div className="column" key={plan.id}>
                    <div className="card">
                        <div className="card-content">
                            <p className="title">{plan.nombre}</p>
                            <p className="subtitle">${plan.precio} MXN/mes</p>
                            <p className="subtitle">{plan.pantallas} {plan.pantallas==1 ? "pantalla" : "pantallas"} simultáneas</p>
                            <button className="button is-success is-fullwidth" onClick={() => setPlan(plan)}><strong>Seleccionar</strong></button>
                        </div>
                    </div>
                </div>
                ))                
                :
               //dibujar formulario de pago
                <div className="column">
                    <div className="card">
                        <div className="card-content">
                            {/* <p className="title">Tus datos de pago</p> */}
                            <p className="subtitle">Plan: {plan.nombre} <br/>
                            Precio: ${plan.precio} MXN/mes <br/>
                            Pantallas: {plan.pantallas}</p>
                          
                            <div>
                            <form id="form-checkout" onSubmit={onSubmit}>

                              <div className="field">
                                  <label className="label">Número de tarjeta</label>
                                  <div className="control has-icons-right">
                                      <input className="input" type="text" placeholder="Número de tarjeta" 
                                      id="cardNumber"
                                      value={cardFormData.cardNumber}
                                onChange={handleChange} 
                                disabled={procesando}
                                  required
                                />
                                  <span className="icon is-small is-right">
                                    <img src={imagen} />
                                  </span>
                                  </div>
                              </div>
                              <div className="field">
                                  <label className="label">Fecha de expiración</label>
                                  <div className="control">

                                    <select className="input" id="expirationMonth" value={cardFormData.expirationMonth} onChange={handleChange}
                                    disabled={procesando}
                                    required
                                    >
                                      <option value="">Mes</option>
                                      <option value="01">01</option>
                                      <option value="02">02</option>
                                      <option value="03">03</option>
                                      <option value="04">04</option>
                                      <option value="05">05</option>
                                      <option value="06">06</option>
                                      <option value="07">07</option>
                                      <option value="08">08</option>
                                      <option value="09">09</option>
                                      <option value="10">10</option>
                                      <option value="11">11</option>
                                      <option value="12">12</option>
                                    </select>

                                  </div>
                                  <div className="control">
                                    <select className="input" id="expirationYear" value={cardFormData.expirationYear} onChange={handleChange}
                                    disabled={procesando}
                                    required
                                    >
                                      <option value="">Año</option>
                                      {Array.from(Array(10).keys()).map((i) => (
                                        <option value={new Date().getFullYear()+i} key={i} >{new Date().getFullYear()+i}</option>
                                      ))}
                                    </select>
                            
                                  </div>
                              </div>
                              <div className="field">
                                  <label className="label">Código de seguridad</label>
                                  <div className="control">
                                      <input className="input" type="number" placeholder="CCV" value={cardFormData.securityCode}

                                      id="securityCode"
                                onChange={handleChange}
                                
                                disabled={procesando}
                                required
                                />
                                  </div>
                              </div>
                              <div className="field">
                                  <label className="label">Nombre en la tarjeta</label>
                                  <div className="control">
                                      <input className="input" type="text" placeholder="Como aparece en la tarjeta"
                                      id="cardholderName"
                                      value={cardFormData.cardholderName}
                                      onChange={handleChange} 
                                      disabled={procesando}
                                      required

                                />
                                  </div>
                              </div>

                              <button className={procesando ? "button is-loading is-fullwidth" : "button is-success is-fullwidth"} type="submit" disabled={procesando}><strong>Pagar</strong></button>
                            </form>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-item" style={{display:'flex', justifyContent:'center', alignItems:'center', gap:'10px'}}>
                            <p className="h4">Pago seguro con</p>
                            <img src={mercadologo} style={{width:'100px'}} />
                          </div>
                        </div>
                    </div>
                </div>
            }
            {
              plan == null ? 
              <div className="column" >
              <div className="card">
                  <div className="card-content">
                      <p className="title">Voucher</p>
                      <p className="subtitle">¿Tienes un voucher?</p>
                      <input className="input" type="text" placeholder="Voucher" style={{marginBottom:'20px'}} onChange={(e) => setVoucher(e.target.value)} />
                      


                      <button className="button is-success is-fullwidth" disabled={voucher!="arribaarribaabajoabajo" || procesando ? true : false} onClick={() => canjeoVoucher()}><strong>Canjear</strong></button>

                      
                  </div>
              </div>
          </div>: null
            }

            </div>


        </div>
    )

}

export default Pay;

