import Serie from "./Serie";
import { initializeApp } from "firebase/app";
import { getFirestore, orderBy } from "firebase/firestore";
import {
  collection,
  getDocs,
  query,
  limit,
  startAt,
  endAt,
} from "firebase/firestore";
import { ClipLoader } from "react-spinners";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Series() {

  const firebaseConfig = {
    apiKey: "AIzaSyAitB4yZLmRCd4NK_y54xNLfs92E3KI22g",
    authDomain: "movies-fc2b1.firebaseapp.com",
    projectId: "movies-fc2b1",
    storageBucket: "movies-fc2b1.appspot.com",
    messagingSenderId: "1028587674738",
    appId: "1:1028587674738:web:ce1c46eac2ced43fb176eb",
    measurementId: "G-ZQNNHLY8DE",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  //obtener movies from firebase
  const [movies, setMovies] = useState([]);
  const [moviesFiltrado, setMoviesFiltrado] = useState([]);
  const [generos, setGeneros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [genero, setGenero] = useState(0);

  const [ordenamiento, setOrdenamiento] = useState(0);
  const [page, setPage] = useState(1);
  const [ano, setAno] = useState(0);
  const [nombre, setNombre] = useState("");

  const obtenerMovies = async () => {
    setLoading(true);
    const db = getFirestore(app);

    //obtener movies from firebase
    //const moviesCol = collection(db, "movies").orderBy("fecha_lanzamiento");
    const moviesCol= query(collection(db, "series"), orderBy("fecha_creacion", "desc"), limit(150));
    const moviesSnapshot = await getDocs(moviesCol);
    const moviesList = moviesSnapshot.docs.map((doc) => doc.data());

    console.log(moviesList.length);
    setMovies(moviesList);
    setMoviesFiltrado(moviesList);
    //setMovies(res.data);
    setLoading(false);
    
  };

  const obtenerGenerosTMDB = async () => {
    const url = `https://api.themoviedb.org/3/genre/tv/list?api_key=27e3e0e4de6cec566e26c2c089566fe7&language=es-MX`;
    const resp = await fetch(url);
    const data = await resp.json();
    setGeneros(data.genres);
  };


  const onChangeAno = (e) => {
    setAno(e.target.value);
  };

  const onchangeGenero = (e) => {
    setGenero(e.target.value);
  };

  const onchangeNombre = (e) => {
    
    if(e.target.value!=""){
      //console.log(movies);
      console.log(e.target.value);
      let data=movies.filter((movie)=>removeAccents(movie.metadatos_the_movie_db.name.toLowerCase()).includes(removeAccents(e.target.value.toLowerCase())));
      //console.log(data);
      setMoviesFiltrado(data);
    }else{
      setMoviesFiltrado(movies);
    }

  };
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } 

  const onchangeOrdenamiento = (e) => {
    setOrdenamiento(e.target.value);
  };

  useEffect(() => {
    if (generos.length === 0) {
      obtenerGenerosTMDB();
    }
    obtenerMovies();
  }, [nombre, ano, genero,ordenamiento]);

  return (
    <>
      <br />
      <div className="container">
        <div className="columns is-mobile is-multiline">
          
          <div className="column is-one-quarter-desktop  is-half-mobile is-half-tablet">
            <div className="field">
              <label className="label">Buscador</label>
              <div className="control">
                <input
                  className="input is-info"
                  type="text"
                  placeholder="Buscar película"
                  onChange={onchangeNombre}
                />
              </div>
            </div>
          </div>
        </div>

        
      <div className="columns is-multiline is-mobile">
        {loading ? (
          //centrar el loader
          <div
            className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ClipLoader size={50} color={"#3e8ed0"} />
          </div>
        ) : (
          //solo mostrar las primeras 10 peliculas
          moviesFiltrado.map((movie,index) => (
            <div key={index} className="column is-one-quarter-desktop  is-half-mobile is-half-tablet">
             
              <Serie key={movie.uid} {...movie} />
             
            </div>
          ))
        )}
      </div>
      </div>
    </>
  );
}
