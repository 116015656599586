import logo from '../../images/logo.svg';
import {Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [activo,setActivo] = useState(false);

  const navigate = useNavigate();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };


  //cocultar navbar burger
  const toggleNavbar = () => {
    
    document.querySelector('.navbar-burger').classList.toggle('is-active');
    document.querySelector('.navbar-menu').classList.toggle('is-active');
    
  };



  useEffect(() => {
   
    if (loading) return;
    if (!user) 
    {
      navigate("/login");
      return;	
    }

    if(user){
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = getDocs(q);
      docs.then((doc) => {
        if (doc.docs[0].data().activo === false){

          navigate("/account");
        }else{
          setActivo(true);
          // navigate("/movies");
        }
      });
      
    }
    
    
    //navigate("/movies");
    fetchUserName();
  }, [user, loading]);

  return (
    <>
    <nav className="navbar" role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
    <a className="navbar-item" href="/">
      <img alt='logo' src={logo} width="130" height="40"  max-height="2.75rem !important" />
    </a>

    <a role="button" onClick={toggleNavbar} className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" className="navbar-menu" style={{boxShadow: 'none'}}>
    {activo?(
    <div className="navbar-start">
      
      <Link to="/movies" className="navbar-item">
        Películas
      </Link>
      <Link to="/series" className="navbar-item">
        Series
      </Link>
      <Link to="/busqueda" className="navbar-item">
        Busqueda web
      </Link>
    </div>
    ):(
      null
    )}

    <div className="navbar-end">
      <div className="navbar-item has-dropdown is-hoverable" >
        {user ? (
          <>
         <a className="navbar-item" style={{display: 'flex', alignItems: 'center', cursor: 'pointer', paddingLeft: '1.5em',gap:'5px'}}>
            <figure className="image is-32x32">
                <img src={user.photoURL} alt="user" referrerPolicy='no-referrer' />
                
            </figure>
            <p className="ml-2">{name}</p>
          </a>
        <div className="navbar-dropdown">
          <a className="navbar-item" href="/account">
            Cuenta
          </a>
          <a className="navbar-item" onClick={logout}>
            Cerrar sesión
          </a>
        </div>
        </>
        ) : (
          null)
        }
      </div>
    </div>
  </div>
</nav>
    {/* <nav className="navbar " role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
      <a className="navbar-item" href="/">
        <img alt='logo' src={logo} width="130" height="40"  max-height="2.75rem !important" />
      </a>
  
      <a href="#"  onClick={toggleNavbar} role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  

   

    {user? (
    <div id="navbarBasicExample" className="navbar-menu">
      <div className="navbar-start">
        <Link to="/movies" className="navbar-item">
          Películas
        </Link>
        <Link to="/series" className="navbar-item">
          Series
        </Link>
      </div>
    </div>

    ):(
      null)}


  


    <div className="navbar-end">

   

      <div className="navbar-item">
        {user ? (
          

          <div className="navbar-item has-dropdown is-hoverable">

              
          

            <a className="navbar-link">
              <figure className="image is-32x32">
                  <img src={user.photoURL} alt="user" />
              </figure>
              <span className="ml-2">{name}</span>
              
            </a>

            <div className="navbar-dropdown">
              <a className="navbar-item" href="/account">
                Cuenta
              </a> <a className="navbar-item" onClick={logout}>
                Logout
              </a>
            </div>
          </div>
        ) : (
          null)
        }
      </div>
    </div>

  </nav> */}
    </>
  )
}