import { initializeApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth} from "../../firebase";
import { useEffect, useState } from 'react';
import {Link  } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    updateDoc,
  } from "firebase/firestore";
  const firebaseConfig = {
    apiKey: "AIzaSyAitB4yZLmRCd4NK_y54xNLfs92E3KI22g",
    authDomain: "movies-fc2b1.firebaseapp.com",
    projectId: "movies-fc2b1",
    storageBucket: "movies-fc2b1.appspot.com",
    messagingSenderId: "1028587674738",
    appId: "1:1028587674738:web:ce1c46eac2ced43fb176eb",
    measurementId: "G-ZQNNHLY8DE",
  };
  const app = initializeApp(firebaseConfig);

  const db = getFirestore(app);
function Account(){
    const [user, loading, error] = useAuthState(auth);
    const [activo,setActivo] = useState(false);
    const [fecha_renovacion,setFecha_renovacion] = useState(null);
    const [planid,setPlanid] = useState(null);
    const [pagos,setPagos] = useState(null);
    const [usuariosPlex,setUsuariosPlex] = useState(null);
    const [usuarioPlex,setUsuarioPlex] = useState(null);
    const [loadingUsuariosPlex,setLoadingUsuariosPlex] = useState(true);
    let navigate = useNavigate();
    const planes = [
        {
            id: 1,
            nombre: 'Premium',
            precio: 129.00,
            pantallas: 4,
        },
        {
            id: 2,
            nombre: 'Estándar',
            precio: 99.00,
            pantallas: 2,
        },
        {
            id: 3,
            nombre: 'Básico',
            precio: 69.00,
            pantallas: 1,
        },
      ]

   


   let obtenerDatos = async () => {
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        docs.forEach((doc) => {
            if (doc.data().activo){
                setActivo(true);
            }
            setUsuarioPlex(doc.data().user_id);
        });

        const q2 = query(collection(db, "pagos"), where("uid", "==", user.uid));
        const docs2 = await getDocs(q2);
        let pagos=[];
        docs2.forEach((doc) => {
            pagos.push(doc);
            setFecha_renovacion(new Date(doc.data().fecha_renovacion.seconds*1000));
            setPlanid(doc.data().idplan);
        });

        //ordenar pagos por fecha
        pagos.sort((a,b) => {
            if (a.data().fecha.seconds > b.data().fecha.seconds){
                return -1;
            }
            if (a.data().fecha.seconds < b.data().fecha.seconds){
                return 1;
            }
            return 0;
        }
        );


        setPagos(pagos);
    
    }

    let obtenerUsuarioPlex = async () => {
        let data= await fetch(`https://us-central1-movies-fc2b1.cloudfunctions.net/usuariosPlex`);
        data = await data.json();
        let usuarios = data.response.data;
        //mandar todos los usermanes a minusculas
        usuarios = usuarios.map(usuario => {
            usuario.username = usuario.username.toLowerCase();
            return usuario;
        });

        //ordernar usuarios por nombre
        usuarios.sort((a,b) => {
            if (a.username > b.username){
                return 1;
            }
            if (a.username < b.username){
                return -1;
            }
            return 0;
        }
        );
        //descargar usuarios que no tienen email
        usuarios = usuarios.filter(usuario => usuario.email !== null && usuario.email !== '');        

        setUsuariosPlex(usuarios);
        setLoadingUsuariosPlex(false);
        //console.log(usuarios);
    }



    useEffect(() => {
        if (user){
            obtenerDatos();
        }
    }, [user]);


    useEffect(() => {
        obtenerUsuarioPlex();
    }, []);

    const handleSelect = async (e) => {
        setUsuarioPlex(e.target.value);
        //actualizar usuario en firebase
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length !== 0) {
            
            let r=await updateDoc(docs.docs[0].ref, {
                user_id: e.target.value,
            });

          
        }
    }


    const handleActivar = async (e) => {
        //verificar que este seleccionado un usuario plex
      
        if (usuarioPlex === '' || usuarioPlex === null || usuarioPlex === undefined){
            alert('Selecciona tu usuario Plex antes de activar la membresía');

            //abrir select
            let select = document.querySelector('select');
            select.focus();
            return;
        }

        //llevar a la pagina de pagos
        
        navigate('/pay');

    }


    return(
        <div>
            <h2 className="title">Cuenta</h2>
            <h2 className="subtitle">Membresía</h2>
            <div className="columns">
                <div className="column" style={{display:'grid',gap: '20px'}}>
                    <div className="card">
                        <div className="card-content">
                            <p className="subtitle"><strong>Nombre:</strong> {user.displayName}</p>
                            <p className="subtitle"><strong>Email:</strong> {user.email}</p>
                            <p className="subtitle" 
                            style={{marginBottom:'10px'}}
                            // style={{display:'flex',alignItems:'center',gap:'10px'}}
                            ><strong>Usuario de Plex:</strong>
                            
                           
                            </p>
                            {user && !loadingUsuariosPlex ? (
                            <div className="select" style={{marginBottom:'20px'}}>
                                <select className="select" onChange={handleSelect} value={usuarioPlex ? usuarioPlex : ''}>
                                    <option value="">Selecciona un usuario</option>
                                    {usuariosPlex && usuariosPlex.map((usuario,index) => (
                                        <option value={usuario.user_id} key={index}>{usuario.username}</option>
                                    ))}
                                </select>
                            </div>
                           
                            ) : (
                                null
                               
                            )
                            }
                           

                            {activo && planid? (
                                <div>
                                    <p className="subtitle" style={{display:'flex',alignItems:'center',gap:'10px'}}><strong>Plan:</strong>{planes.find(plan => plan.id === planid).nombre} ({planes.find(plan => plan.id === planid).pantallas} pantallas)</p>
                                    <p className="subtitle" style={{display:'flex',alignItems:'center',gap:'10px'}} ><strong>Estado:</strong><span className="tag is-success is-small">Activo</span></p>
                                   
                                    <p className="subtitle"><strong>Fecha de renovación:</strong> {fecha_renovacion? fecha_renovacion.toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }): null}</p>
                                    {/* <button className="button is-danger is-small">Cancelar membresía</button> */}
                                </div>
                            ):(
                            <div>
                                <p className="subtitle" style={{display:'flex',alignItems:'center',gap:'10px'}} ><strong>Estado:</strong><span className="tag is-danger is-small">Inactivo</span></p>
                                <button className="button is-success is-fullwidth" onClick={handleActivar}><strong>Activar membresía</strong></button>
                                
                            </div>
                            )}
                        </div>
                       
                    </div>
                    {pagos && pagos.length > 0 && (
                    <div className="card">
                        <div className="card-content">
                            <p className="subtitle"><strong>Tus pagos</strong></p>
                            <table className="table is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Plan</th>
                                        <th>Precio</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {pagos && pagos.map((pago) => (
                                        <tr key={pago.id}>
                                            <td>{new Date(pago.data().fecha.seconds*1000).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</td>
                                            <td>Plan {pago.data().plan}</td>
                                            <td>${pago.data().precio}.00 MXN</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>

                            </table>



                        </div>
                       
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}


export default Account;
